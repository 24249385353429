@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

:where(body) {
  width: 100%;
}

:where(html:not(.evechat-embedded)) {
  @include mix.base-styles;
  width: 100vw;
  min-height: var(--ha-vh100);
  overflow-x: hidden;

  @media (max-width: var.$container-width + 30px) {
    width: 100%;
    overflow-x: initial;
  }

  body {
    @include mix.root-element-styles;
    width: 100%;
    min-height: var(--ha-vh100);
  }
}

:where(.evechat-embedded .evechat-root) {
  width: 100%;
  min-height: min(var(--ha-embedded-min-height), var(--ha-vh100));

  @include mix.root-element-styles;
  @include mix.base-styles;
}

:where(.evechat-embedded .evechat-form-portal) {
  @include mix.root-element-styles;
  @include mix.base-styles;
}

.overflowHidden {
  overflow: hidden;
}

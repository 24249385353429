/* stylelint-disable selector-class-pattern */
@use 'src/styles/scss_variables' as var;

.Toastify__toast {
  min-width: 300px;
  max-width: 400px;
  padding: 20px;
  padding-right: 28px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 0.1);
  color: var.$primary-text-color;
  font-size: 14px;
  line-height: 1.3;
}

.Toastify__toast-container {
  display: flex;
  width: auto;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
}

.Toastify__close-button {
  position: absolute;
  top: 8px;
  right: 7px;
  display: flex;
  width: 10px;
  height: 10px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-icon {
  margin-inline-end: 12px;

  svg {
    fill: var.$success-color;
  }
}

.Toastify__progress-bar[aria-hidden='true'] {
  opacity: 0;
  visibility: hidden;
}

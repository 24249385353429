@use 'sass:math';
@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.main input,
.main p,
.main ol,
.main li {
  padding: 0;
  margin: 0;
}

.main ol {
  padding-left: 20px;
}
.main p + ol {
  margin: 10px 0;
}
.main li + li {
  margin-top: 10px;
}

.main {
  --primary-color: #99cd4d;
  --send-color: white;
  --receive-bg: #ebebee;
  --receive-text: black;
  --page-background: white;

  font-family: 'Inter', 'Helvetica Neue', Helvetica, sans-serif;

  height: 700px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.mainEmbedded {
  overflow: hidden;
}

.logo {
  height: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.chatContainer {
  width: 400px;
  max-width: 100vw;
  border-radius: 15px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: linear-gradient(320deg, #a17f91, #6d51aa);
}

.chatHeader {
  color: #fff;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
}
.chatHeader::before {
  content: '';
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: -1;

  display: block;
  width: calc(100% + 80px);
  height: calc(100% + 80px);

  filter: blur(20px);
}

.chatHeaderImage {
  width: 80px;
  height: 80px;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 10000px;
  background: url(../../images/eve.png) center center / contain no-repeat;
  background-color: #ffffffe6;
  background-origin: content-box;
  overflow: hidden;
}
.chatHeaderTitles {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}
.chatHeaderTitle1 {
  font-size: 26px;
  font-weight: 600;
}
.chatHeaderTitle2 {
  margin-top: 4px;
  opacity: 0.9;
  line-height: 19px;
}

.userDataModal {
  background-color: white;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0 50px;
}
.userDataModalTitle {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  align-self: center;
}
.userDataModalSubtitle {
  font-size: 17px;
  margin-bottom: 40px;
  color: #777;
  align-self: center;
}
.userDataModalInput {
  padding: 10px 50px 10px 10px !important;
  border: 1px solid #ebebee;
  border-radius: 8px;
  font-size: 16px;
  background-color: #ffffff90;
  margin-bottom: 12px !important;
}
.userDataModal button[type='submit'] {
  display: flex;
  align-items: center;

  align-self: center;
  border: none;
  padding: 12px 18px;
  margin-top: 40px;
  font-size: 16px;
  color: white;
  font-weight: 600;
  border-radius: 100px;
  cursor: pointer;
  background-color: var(--primary-color);
}
.userDataModal button[type='submit'] svg {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  fill: currentColor;
}
.termsCheckbox {
  display: flex;
  align-items: flex-start;
  color: #999;
  font-size: 13px;
  margin-top: 10px;
}
.termsCheckbox input {
  flex-shrink: 0;
  margin-right: 6px !important;
  padding: 0 !important;
}
.termsCheckbox a {
  color: inherit;
  text-decoration: underline;
}

.chatHistory {
  background-color: var(--page-background);
  padding: 10px 14px 0;
  height: 400px;
  /* max-height: 80vh; */
  overflow-y: scroll;
  overflow-x: hidden;

  font-size: 16px;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.chatHistory::-webkit-scrollbar {
  display: none;
}

.chatForm {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
.chatForm::before {
  content: '';
  position: absolute;
  left: -40px;
  top: -40px;
  z-index: -1;

  display: block;
  width: calc(100% + 80px);
  height: calc(100% + 80px);

  filter: blur(20px);
}

.chatForm input[type='text'] {
  flex-grow: 1;
  padding: 10px 50px 10px 10px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  background-color: #ffffffc8;
}
.chatForm input[type='text']:disabled {
  opacity: 0.5;
  cursor: auto;
}

.chatForm button[type='submit'] {
  border: none;
  padding: 0;
  cursor: pointer;

  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 9px;
}
.chatForm button[type='submit']:disabled {
  opacity: 0.5;
  cursor: auto;
}

.message {
  max-width: 255px;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  position: relative;
  padding: 6px 12px;
  border-radius: 12px;
  /* white-space: pre-wrap; */
}
.message::before,
.message::after {
  content: '';
  position: absolute;
  bottom: 0;
  height: 25px;
}

.userMessage {
  color: var(--send-color);
  background: var(--primary-color);
  align-self: flex-end;
}
.userMessage::before {
  right: -7px;
  width: 20px;
  background-color: var(--primary-color);
  border-bottom-left-radius: 16px 14px;
}
.userMessage::after {
  right: -26px;
  width: 26px;
  background-color: var(--page-background);
  border-bottom-left-radius: 10px;
}

.assistantMessage,
.assistantLoading {
  background-color: #ebebee;
  align-self: flex-start;
  background: var(--receive-bg);
  color: black;
  align-self: flex-start;
}
.assistantMessage:before {
  left: -7px;
  width: 20px;
  background-color: var(--receive-bg);
  border-bottom-right-radius: 16px 14px;
}
.assistantMessage::after {
  left: -26px;
  width: 26px;
  background-color: var(--page-background);
  border-bottom-right-radius: 10px;
}

.assistantLoading {
  font-style: italic;
}

.saveChatContainer {
  padding: 8px 0;
  border-top: 1px solid #ebebee;
  background-color: white;
}

.saveChatButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
}
.saveChatButton > svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.saveChatButtonSaved {
  color: #30be47;
  cursor: auto;
}

.hidden {
  display: none;
}

.footer {
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #777;
}
.footerSection + .footerSection {
  margin-top: 10px;
}
.footerSection a {
  color: inherit;
  text-decoration: underline;
}

@use 'src/styles/scss_variables' as var;

.linkButton {
  display: inline-block;
  text-decoration: none;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
  }
}

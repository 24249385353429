.mainLayout {
  position: relative;
  min-height: inherit;
}

.noopEnterAnimation {
  animation: noop-keyframes 0.1s;
}

.exitAnimation {
  animation-duration: 1s;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fade-out;
  animation-timing-function: ease;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes noop-keyframes {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

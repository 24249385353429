@use 'src/styles/scss_variables' as var;

@mixin input-box-styles {
  border: 1px solid var.$border-color;
  background: white;
  border-radius: 7px;
  box-shadow: 0 2px 8px rgb(171 171 171 / 0.1);
}

@mixin input-text-styles {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

@mixin object-fit-cover {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  object-fit: cover;
}

@mixin absolute-inset($top: 0, $right: $top, $bottom: $top, $left: $top) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin base-font-styles {
  color: var.$primary-text-color;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: initial;
  font-weight: 400;
  letter-spacing: 0;
  word-break: break-word;
}

@mixin root-element-styles {
  position: relative;
  margin: 0;
  hyphens: auto;

  @include base-font-styles;

  * {
    box-sizing: border-box;
    text-size-adjust: none;
  }
}

@mixin base-styles {
  button,
  input,
  textarea {
    font-family: inherit;
    outline: none;
  }

  img,
  video {
    max-width: 100%;
  }

  b {
    font-weight: 500;
  }

  em,
  i {
    font-style: italic;
  }

  svg {
    fill: currentColor;
    vertical-align: top;
  }

  a {
    color: var(--evechat-primary-color);
    text-decoration: none;
  }

  a.mailto {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: inherit;
    font-weight: 400;
    line-height: normal;
  }
}

@mixin box-shadow-with-alpha($box-shadow, $alpha: 1) {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: $box-shadow;
    content: '';
    opacity: $alpha;
    transition: all 0.2s ease;
  }
}

@mixin background-with-alpha($color, $alpha: 1) {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    border-radius: inherit;
    content: '';
    opacity: $alpha;
    transition: all 0.2s ease;
  }
}

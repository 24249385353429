// Colors
$primary-text-color: #535353;
$border-color: #ebebee;
$transparent-white: rgb(255 255 255 / 0);
$success-color: #34c759;
$warning-color: #ff9500;
$main-color: #ffd609;

// Sizes
$container-width: 1000px;
$container-mobile-h-padding: 20px;

// Responsive breakpoints
$mobile-breakpoint-s: 375px;
$mobile-breakpoint-m: 480px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;

@use 'src/styles/scss_variables' as var;
@use 'src/styles/mixins' as mix;

.container {
  display: flex;
  overflow: auto;
  min-height: inherit;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
}

.title,
.subtitle {
  text-align: center;
}

.title {
  margin-bottom: 35px;
  font-size: 26;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.34px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.26px;
}

.button {
  margin: 42px auto 0;
}
